import React from 'react'
import './contacts.css'
export const Contact = () => {
	return (
		<>
			<div className='contact-page-container'>
				<div className='contact-title'>Contact Us</div>
				<p className='contact-text'>
					<p>Phone:</p>
					<p>+370 692 10831</p>
				</p>
				<p className='contact-text'>
					<p>Website:</p>
					<p>https://vivatpay.eu/</p>
				</p>
				<p className='contact-text'>
					<p>E-mail:</p>
					<p>vivatpay@gmail.com</p>
				</p>
			</div>
		</>
	)
}

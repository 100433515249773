import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import logo from '../../images-2/logo.svg'
import './header2.css'

export const Header2 = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const path = location.pathname.includes('account')
	const qr = localStorage.getItem('qr')

	return (
		<header className='header2'>
			<NavLink to='/'>
				<img src={logo} alt='logo' />
			</NavLink>
			<nav className='nav-item2'>
				{!path && (
					<>
						<NavLink
							to='/about'
							className={({ isActive }) => (isActive ? 'active-link' : '')}
						>
							{t('About')}
						</NavLink>
						<NavLink
							to='/how-start'
							className={({ isActive }) => (isActive ? 'active-link' : '')}
						>
							{t('How to Start')}
						</NavLink>

						<div className='dropdown'>
							<NavLink
								to='/for-staff'
								className={({ isActive }) => (isActive ? 'active-link' : '')}
							>
								{t('For Staff')}
							</NavLink>
							<div className='dropdown-content'>
								<NavLink to='/for-staff-waiters'>{t('Waiters')}</NavLink>
								<NavLink to='/for-staff/taxi'>{t('Taxi Drivers')}</NavLink>
							</div>
						</div>

						<div className='dropdown'>
							<NavLink
								to='/for-business'
								className={({ isActive }) => (isActive ? 'active-link' : '')}
							>
								{t('For Business')}
							</NavLink>
							<div className='dropdown-content'>
								<NavLink to='/for-business/restaurants'>
									{t('Restaurants')}
								</NavLink>
								<NavLink to='/for-business/taxi'>{t('Taxi')}</NavLink>
							</div>
						</div>
					</>
				)}

				{path && (
					<>
						{qr && <NavLink to='/account/qr'>{t('QR')}</NavLink>}
						<NavLink to='/account'>{t('Personal account')}</NavLink>
						{/* <NavLink to='/account/verify'>{t('ID Verification')}</NavLink> */}
						<NavLink to='/account/transactions'>{t('Transactions')}</NavLink>
					</>
				)}
			</nav>

			<NavLink to='/login'>
				<div className='btn-blue'>
					<p>{path ? t('Logout') : t('Start')}</p>
				</div>
			</NavLink>
		</header>
	)
}

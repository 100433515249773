import { QRCodeCanvas } from 'qrcode.react'
import React from 'react'
import './qr.css'

export const Qr = () => {
	// const handleCopyClick = () => {
	// 	navigator.clipboard
	// 		.writeText(url)
	// 		.then(() => {
	// 			toast.success('URL copied to clipboard!')
	// 		})
	// 		.catch(err => {
	// 			console.error('Ошибка при копировании: ', err)
	// 		})
	// }
	return (
		<>
			<section className='qr'>
				<div className='qr-block'>
					<div className='qr-title'>Your personal QR-code</div>
					<div>
						<QRCodeCanvas size={300} level='H' />
					</div>
					<div className='qr-number'>999-999-999</div>
					<div className='qr-text'>Go to the payment page</div>
					{/* <div className='qr-url'>
						<div className='qr-url-text'>{url}</div>
						<span className='qr-copy' onClick={handleCopyClick}>
							<img src={copyImg} alt='' />
						</span>
					</div> */}
				</div>
				<div className='qr-transactions-block'>
					<div className='transactions-title'>Transactions</div>
					<div className='transactions-text'>
						You have not had any transactions yet.
					</div>
				</div>
			</section>
		</>
	)
}

import React from 'react'
import { HowSection1 } from '../../components-2/HowToStartComponents/howSection1/howSection1'
import { HowSection2 } from '../../components-2/HowToStartComponents/howSection2/howSection2'

export const HowStart = () => {
	return (
		<>
			<HowSection1 />
			<HowSection2 />
		</>
	)
}

import { useTranslation } from 'react-i18next'
import item from '../../../images-2/section6Logo.svg'
import './section6.css'

export const Section6 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='section6'>
				<img src={item} alt='' />

				<div className='section6-block'>
					<div className='section6-title'>{t('section6-title')}</div>
					<div className='section6-text'>{t('section6-text')}</div>
				</div>
			</section>
		</>
	)
}

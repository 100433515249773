import React from 'react'
import { useTranslation } from 'react-i18next'
import iconPay from '../../../images-2/section2Pay.svg'
import iconQr from '../../../images-2/section2QR.svg'
import iconRegister from '../../../images-2/section2Registration.svg'
import './section2.css'
export const Section2 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section2'>
				<div className='section2-title'>{t('How it works')}</div>
				<div className='section2-icon'>
					<div className='section2-icon-text'>
						<img src={iconRegister} alt='' />
						<div className='section2-card-block'>
							<div className='section2-icon-text-block'>
								<span>1</span>
								{t('section2-register')}
							</div>
							<div>{t('section2-register-text')}</div>
						</div>
						<div className='bg'></div>
					</div>

					<div className='section2-icon-text'>
						<img src={iconQr} alt='' />
						<div className='section2-card-block'>
							<div className='section2-icon-text-block'>
								<span>2</span>
								{t('section2-QR')}
							</div>
							<div>{t('section2-QR-text')}</div>
						</div>
						<div className='bg'></div>
					</div>

					<div className='section2-icon-text'>
						<img src={iconPay} alt='' />
						<div className='section2-card-block'>
							<div className='section2-icon-text-block'>
								<span>3</span>
								{t('section2-payment')}
							</div>
							<div>{t('section2-payment-text')}</div>
						</div>
						<div className='bg'></div>
					</div>
				</div>
			</section>
		</>
	)
}

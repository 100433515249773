import { TextField } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import logo2 from '../../images-2/loginLogo2.svg'
import './login.css'

export const Registration = () => {
	return (
		<>
			<section className='registr'>
				<div className='form'>
					<div className='login-form-title'>
						Fill out the form to connect to the service
					</div>
					<div className='input-block'>
						<TextField
							label='Your Name'
							variant='standard'
							sx={{ width: '100%', marginBottom: '24px' }}
						/>
						<TextField
							label='Organization Name'
							variant='standard'
							sx={{ width: '100%', marginBottom: '24px' }}
						/>
						<TextField
							label='Phone'
							variant='standard'
							sx={{ width: '100%' }}
						/>
					</div>
					<div className='btn-block-login'>
						<button className='btn-green'>Submit Application</button>
						<img className='login-logo2' src={logo2} alt='' />
					</div>
					<div className='complete-text-2'>
						By clicking the button, I agree to the Terms of Use and the personal
						Privacy Policy.
					</div>
				</div>
				<div className='form'>
					<div className='login-form-title'>Registration</div>

					<div className='register-text mt24px'>You are registering as</div>

					<NavLink to='/registration/form'>
						<div className='btn-block-login'>
							<button className='btn-green'>Tip Recipient</button>
							<img className='login-logo2' src={logo2} alt='' />
						</div>
					</NavLink>
					<div className='register-text-2'>
						for example: waiter, delivery man, bartender, cashier, tour guide
					</div>

					<div className='or-block'>
						<div className='or-text'>or</div>
					</div>

					<NavLink to='/registration/form'>
						<div className='btn-block-login'>
							<button className='btn-blue'>Administrator</button>
							<img className='login-logo2' src={logo2} alt='' />
						</div>
					</NavLink>
					<div className='register-text-2'>
						for example: restaurant, café, beauty salon
					</div>
				</div>
			</section>
		</>
	)
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import img from '../../images-2/verifyEnd.svg';

export const VerifyStep3 = ({ onBack }) => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('qr', true);
        const timer = setTimeout(() => {
            navigate('/account/qr');
        }, 2000);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className='end-block'>
            <img src={img} alt='' />
            <div className='verify-end'>
                <p>Your Identity Verification is successful!</p>
            </div>
        </div>
    );
};

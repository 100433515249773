import FaqComponent from '../../components-2/FAQ/faq'
import { Section1 } from '../../components-2/HomeComponents/HomeSection1/Section1'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section11 } from '../../components-2/HomeComponents/HomeSection11/section11'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section5 } from '../../components-2/HomeComponents/HomeSection5/section5'
import { Section6 } from '../../components-2/HomeComponents/HomeSection6/section6'
import { Section7 } from '../../components-2/HomeComponents/HomeSection7/section7'
import { Section8 } from '../../components-2/HomeComponents/HomeSection8/section8'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'


export const Home2 = () => {
	return (
		<>
			<Section1 />
			<Section2 />
			<Section3 />
			<Section4 />
			<Section5 />
			<Section6 />
			<Section7 />
			<Section8 />
			<Section9 />
			<Section10 />
			<Section11 />
			<FaqComponent />
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import img1 from '../../../images-2/section9Img1.svg'
import img2 from '../../../images-2/section9Img2.svg'
import img3 from '../../../images-2/section9Img3.svg'
import img4 from '../../../images-2/section9Img4.svg'
import img5 from '../../../images-2/section9Img5.svg'
import './section9.css'
export const Section9 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section9'>
				<div className='section9-title'>{t('section9-title')}</div>
				<div className='section9-container'>
					<div className='section9-item'>
						<div>
							<img src={img1} alt='' />
						</div>
						<div>
							<div className='section9-item-title'>
								{t('section9-item1-title')}
							</div>
							<div className='section9-item-text'>
								{t('section9-item1-text')}
							</div>
						</div>
					</div>
					<div className='section9-item'>
						<div>
							<img src={img2} alt='' />
						</div>
						<div>
							<div className='section9-item-title'>
								{t('section9-item2-title')}
							</div>
							<div className='section9-item-text'>
								{t('section9-item2-text')}
							</div>
						</div>
					</div>
					<div className='section9-item'>
						<div>
							<img src={img3} alt='' />
						</div>
						<div>
							<div className='section9-item-title'>
								{t('section9-item3-title')}
							</div>
							<div className='section9-item-text'>
								{t('section9-item3-text')}
							</div>
						</div>
					</div>
					<div className='section9-item'>
						<div>
							<img src={img4} alt='' />
						</div>
						<div>
							<div className='section9-item-title'>
								{t('section9-item4-title')}
							</div>
							<div className='section9-item-text'>
								{t('section9-item4-text')}
							</div>
						</div>
					</div>
					<div className='section9-item'>
						<div>
							<img src={img5} alt='' />
						</div>
						<div>
							<div className='section9-item-title'>
								{t('section9-item5-title')}
							</div>
							<div className='section9-item-text'>
								{t('section9-item5-text')}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

import { useTranslation } from 'react-i18next'
import './section3.css'
import item from '../../../images-2/section3Payment.svg'

export const Section3 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='section3'>
				<div className='section1-block'>
					<div className='section1-title'>{t('section3-title')}</div>
					<div className='section1-text'>{t('section3-text')}</div>
				</div>
				<img src={item} alt='' />
			</section>
		</>
	)
}

import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import item from '../../../images-2/section7Logo.svg'
import './section7.css'

export const Section7 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='section7'>
				<div className='section7-block'>
					<div className='section7-title'>{t('section7-title')}</div>
					<div className='section7-text'>{t('section7-text')}</div>
					<div className='section7-list'>
						<ul>
							<li>Connect several people at once</li>
							<li>
								View the tip earnings of your staff, since tips are an indicator
								of employee performance.
							</li>
							<li>
								Mass print of business cards – there’s no need to wait for
								employees to start registering on their own.
							</li>
						</ul>
					</div>

					<NavLink to='/registration/form'>
						<div className='btn-block'>
							<button className='btn-blue'>Register as an administrator</button>
						</div>
					</NavLink>

					<div className='section7-text'>{t('section7-text-2')}</div>
				</div>
				<img src={item} alt='' />
			</section>
		</>
	)
}

import { Box, Button, Step, StepLabel, Stepper } from '@mui/material'
import React, { useState } from 'react'
import { VerifyStep1 } from './VerifyStep1'
import { VerifyStep2 } from './VerifyStep2'
import { VerifyStep3 } from './VerifyStep3'

const steps = ['Upload Documents', 'Processing', 'Verification Status']

export const Verify = () => {
	const [activeStep, setActiveStep] = useState(0)

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const renderStepContent = () => {
		switch (activeStep) {
			case 0:
				return <VerifyStep1 onNext={handleNext}/>
			case 1:
				return <VerifyStep2 onNext={handleNext} onBack={handleBack}/>
			case 2:
				return <VerifyStep3 onBack={handleBack}/>
			default:
				return null
		}
	}

	return (
		<Box sx={{ width: '100%', marginTop: '50px' }}>
			<Stepper activeStep={activeStep} >
				{steps.map(label => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Box sx={{ mt: 5, mb: 2 }}>
				{renderStepContent()}
			</Box>
		</Box>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import item from '../../../images-2/section4Logo.svg'
import './section4.css'

export const Section4 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='section4'>
				<img src={item} alt='' />

				<div className='section4-block'>
					<div className='section4-title'>{t('section4-title')}</div>
					<div className='section4-text'>{t('section4-text')}</div>
				</div>
			</section>
		</>
	)
}

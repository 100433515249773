import React from 'react'
import imgPerson from '../../images-2/accountPerson.svg'
import './account.css'
import { Outlet } from 'react-router-dom'

export const Account = () => {
	const data = JSON.parse(localStorage.getItem('formData'))

	return (
		<>
			<section className='account'>
				<div className='account-block'>
					<div className='account-name'>
						<img src={imgPerson} alt='' /> {data.firstName} {data.lastName}
					</div>
					<div className='account-balance'>
						<span className='balance-text'>Balance:</span>{' '}
						<span className='balance-text-money'>0 € </span>
					</div>
				</div>

				<Outlet />
			</section>
		</>
	)
}

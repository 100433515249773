import { useTranslation } from 'react-i18next'
import './about2.css'
export const About2 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='about2'>
				<div className='title'>{t('about-2-title')}</div>

				<div className='about2-text mt24px'>{t('about-2-title-text-1')}</div>

				<div className='about2-text mt24px'>{t('about-2-title-text-2')}</div>

				<div className='about2-title-2'>{t('about-2-title-2')}</div>

				<div className='about2-text-2-block'>
					<div className='about2-text-2-block-text-1'>
						<div className='business job-title'>{t('about-2-business')}</div>
						<div className='job-text'>{t('about-2-business-text')}</div>
					</div>
					<div className='about2-text-2-block-text-2'>
						<div className='staff job-title'>{t('about-2-staff')}</div>
						<div className='job-text'>{t('about-2-staff-text')}</div>
					</div>
				</div>

				<div className='about2-title-2'>{t('about-2-title-3')}</div>
				<div className='about2-text mt24px'>{t('about-2-text-3')}</div>

				<div className='about2-text mt24px ul'>
					<ul>
						<li>{t('about-2-text-ul-1')}</li>
						<li>{t('about-2-text-ul-2')}</li>
						<li>{t('about-2-text-ul-3')}</li>
						<li>{t('about-2-text-ul-4')}</li>
					</ul>
				</div>

				<div className='about2-text mt24px'>{t('about-2-text-4')}</div>
				<div className='about2-title-2'>{t('about-2-title-4')}</div>
				<div className='about2-text mt24px'>{t('about-2-text-5')}</div>
				<div className='about2-text mt24px'>{t('about-2-text-6')}</div>

				<div className='about2-title-2'>{t('about-2-title-5')}</div>

				<div className='about2-text mt24px ul'>
					<ul>
						<li>{t('about-2-text-ul-5')}</li>
						<li>{t('about-2-text-ul-6')}</li>
						<li>{t('about-2-text-ul-7')}</li>
						<li>{t('about-2-text-ul-8')}</li>
						<li>{t('about-2-text-ul-9')}</li>
					</ul>
				</div>




			</section>
		</>
	)
}

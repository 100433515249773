const header = {
	About: 'About',
	Start: 'Start',
	'How Start': 'How Start',
	'For Staff': 'For Staff',
	'For Business': 'For Business',
}

const text = {
	'section1-title': 'Accept tips from a card',
	'section1-btn': 'Register now',
	'section1-text':
		'Accept tips from a card easily, quickly, and securely. Registration takes a minute, instant withdrawal of funds to your card',
	'section2-register': 'Registration',
	'section2-register-text':
		'You register (1 minute) and receive a personal QR code.',
	'section2-QR': 'QR-code',
	'section2-QR-text':
		'Show the client/guest the QR code either in printed form or onyour phone’s screen. You can download print templates or orderdelivery in your personal account.',
	'section2-payment': 'Payment',
	'section2-payment-text':
		'The client scans the QR code, is directed to your personal page,and leaves a cashless tip. The payment process takes about a minute.',
	'section3-title': 'How payments are made',
	'section3-text':
		'Guests and clients pay electronic tips with a card or through payment systems using a QR code. Only a smartphone is needed for the payment; there is no need to install any applications.',
	'section4-title': 'Withdrawal of funds',
	'section4-text':
		'Money is withdrawn instantly; you only need to provide your card number.',
	'section5-title': 'Who needs our service',
	'section5-text':
		'Anyone who receives tips. Often, clients or guests either have no cash or do not have small banknotes.',
	'section6-title': 'Integration with cash register systems',
	'section6-text':
		'Our service easily connects to your restaurant`s automation system. This allows you to print employee QR codes directly on the receipts. Our technical support staff will help you set up the plugin for integration.',
	'section7-title': 'For business owners',
	'section7-text':
		'Do you want to implement our VivatPay tip service at your company? Register as an administrator, and you will be able to:',
	'section7-text-2':
		'With our service, your staff is guaranteed to receive more tips, and it`s free for you. We will handle all accounting and legal matters.',
	'section8-title': 'Where to place the QR code',
	'section8-card1': 'Insert business cards into the bill holder',
	'section8-card2': 'Put stickers with the QR-code on tables',
	'section8-card3': 'Place on receipts',
	'section8-card4': 'Show on the phone',
	'section8-card5': 'Display on the badge',
	'section8-card6': 'Send via link',
	'section9-title': 'VivatPay - is convenient',
	'section9-item1-title': 'Instant withdrawal',
	'section9-item1-text':
		'The system is fully automated, so there`s no need to wait.',
	'section9-item2-title': 'Privacy',
	'section9-item2-text':
		'The payer does not see your personal data, only the QR code.',
	'section9-item3-title': 'Printed materials',
	'section9-item3-text':
		'Download and send ready-made designs for business cards and stickers for printing.',
	'section9-item4-title': 'Transparency, control',
	'section9-item4-text':
		'All incoming tips are visible immediately: when and how much was left for you.',
	'section9-item5-title': 'Payment security',
	'section9-item5-text': 'All transactions are protected.',
	'section10-title': 'How much does it cost?',
	'section10-block-title': 'Service fee for VivatPay',
	'section10-block-text':
		'Always 0% for employees and for the institution! No additional fees or subscription costs.',
	'section11-block-title': 'So, shall we get started?',
	'section11-block-text':
		'Registration will take a minute, and you can start accepting tips right away.',
	'section11-block-btn': 'Register now',

	'about-2-title': 'About the Company',
	'about-2-title-text-1':
		'The company "VivatPay" offers a convenient, quick, and affordable way to pay tips – via QR code. Our service was developed for waiters, baristas, hairdressers, cleaners, taxi drivers, and other service industry professionals. It is designed to significantly simplify and speed up the process of leaving tips. For employees to start using it, all they need to do is connect to the system, complete a one-minute registration, and print their personal QR code.',
	'about-2-title-text-2':
		'We collaborate with many popular and leading banks in the country, which simplifies the withdrawal of received funds. All transactions go through their payment gateways, ensuring that operations are fully protected. VivatPay easily integrates with modern cash register software such as R-Keeper and IIKO. From the customer’s perspective, only a phone is required. However, our company continues to develop, striving to provide our clients with an effective tool for receiving tips for quality services.',
	'about-2-title-2': 'Who the VivatPay Service Is Suitable For',
	'about-2-business': 'Business Owners',
	'about-2-business-text':
		'Thanks to the convenient presentation of all credited payments, you will be able to track the amount of incoming money, the frequency of transactions, and assess the effectiveness and productivity of each connected employee. For this purpose, the service includes a role for "Administrator."',
	'about-2-staff': 'Staff',
	'about-2-staff-text':
		'Receive tips from guests in few seconds without the need to install applications or go through complicated registration. The customer only needs to scan your individual QR code and enter the amount. The money is transferred instantly.',
	'about-2-title-3': 'Our Goals',
	'about-2-text-3':
		'The money left by visitors as tips for staff is often a primary source of income for them. Since cash transactions gradually decrease, customers at cafes, restaurants, hair salons, and other establishments are leaving tips less frequently. This directly impacts employee salaries. This is the problem we aim to solve by developing, implementing, and enhancing VivatPay.',
	'about-2-text-ul-1':
		'We have eliminated the possibility for administrators or owners of cafes, hotels, etc. to withdraw funds. Tips will go to the person who earned them.',
	'about-2-text-ul-2':
		'We have simplified all actions for restaurant, taxi, and other clients—having a smartphone is sufficient.',
	'about-2-text-ul-3':
		'We have organized a transparent and clear system for all transactions.',
	'about-2-text-ul-4':
		'We have reduced the tip payment time to 2-3 seconds for both parties.',
	'about-2-text-4':
		'By connecting to our system, staff receive a fast and modern tool that can help them earn more money for providing good service.',
	'about-2-title-4': 'How We Created the VivatPay Service',
	'about-2-text-5':
		'Since we initially aimed to develop a service that wouldn`t burden either the payer or the recipient, we eliminated many features commonly used in other similar applications. We removed the need for customer to register in our service, which reduced their actions to just a couple of clicks. We simplified the connection process to our system for waitstaff, delivery men, and others. By partnering with leading banks in the country the withdrawal of received funds became simpler and safer. We also provided for integration with cash register software such as R-Keeper and IIKO.',
	'about-2-text-6':
		'The service is not an application; it doesn’t need to be downloaded or installed. It is enough to connect to our system and start working. The interface is intuitive for any smartphone owner. An individual web client is created for the user, and a unique QR code is generated.',
	'about-2-title-5': 'Why You Should Use Our Service',
	'about-2-text-ul-5': 'Instant withdrawal of funds to your bank card;',
	'about-2-text-ul-6':
		'The payer will not see your personal information during the transfer;',
	'about-2-text-ul-7':
		'QR code is in a convenient format for printing on business cards, receipts, badges, etc.;',
	'about-2-text-ul-8': 'Reports on incoming funds are displayed immediately;',
	'about-2-text-ul-9': 'Convenient push notifications to your smartphone.',
	'how-section1-title': 'How to Start Accepting Tips via Card',
	'how-section1-text1': 'Guests often do not have cash or small banknotes.',
	'how-section1-text2': 'With our service, you can accept tips from guests via card or through payment systems using QR codes.',
}

export const en = {
	...header,
	...text,
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section11 } from '../../components-2/HomeComponents/HomeSection11/section11'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'
import logo from '../../images-2/section6Logo.svg'
import './style.css'
export const ForStaffTaxi = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='staff-section1'>
				<div className='staff-section1-block'>
					<div className='staff-section1-title'>Tips for Taxi Drivers</div>
					<div className='staff-section1-text'>
						Now taxi drivers can quickly and conveniently receive cashless tips
						thanks to the VivatPay service. It only takes a few seconds. The
						customer just needs to scan the driver's QR code with the phone
						camera and leave a tip. A beautiful sticker with the driver's
						personal QR code can be placed on the back seat and central panel of
						the car or even on the window. This way, more customers will be able
						to thank you for the quality service you provide in your taxi.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>

			<section className='waiters-description'>
				<div className='waiters-title'>When to Leave Tips in Taxi</div>
				<div className='waiters-text mt24px'>
					Tips can be left for taxi drivers in several situations:
				</div>

				<div className='waiters-text mt24px'>
					<ul>
						<li className='mt24px'>
							You liked the service – politeness, tactfulness, helpfulness, etc.
						</li>
						<li className='mt24px'>
							The car arrived quickly and didn't take a long time to reach you;
						</li>
						<li className='mt24px'>
							The interior is clean and tidy, with no unpleasant odors;
						</li>
						<li className='mt24px'>
							There is either unobtrusive music or none at all;
						</li>
						<li className='mt24px'>
							The driver helped with loading and unloading bags or items;
						</li>
						<li className='mt24px'>
							The driver found a convenient spot for you to get in;
						</li>
						<li className='mt24px'>
							If there was a conversation, the discussion was pleasant and
							engaging;
						</li>
						<li className='mt24px'>Personal appreciation for the driver;</li>
						<li className='mt24px'>
							Assistance that goes beyond the standard services provided.
						</li>
					</ul>
				</div>

				<div className='waiters-text mt24px'>How Much to Tip a Taxi Driver</div>

				<div className='waiters-text mt24px'>
					Tips in taxi are rarely given, as it has not traditionally been
					common. Additionally, popular services that has apps already have such
					an option included. However, not popular taxi services do not have
					such a service. Therefore, monetary gratitude serves as an incentive
					for drivers to improve their service and to be polite and
					accommodating with every customer. It also provides, even if it's a
					small amount, an additional boost to their overall earnings, thereby
					increasing their quality of life.
				</div>
			</section>

			<div className='waiters-text mt24px'>
				Traditional tips for taxi drivers range from 5-10%, but you can leave up
				to 100% if the ride was completely satisfactory or if some unexpected
				assistance was provided. However, as the circulation of cash decreases
				gradually, the amount of money left as tips is also declining. But there
				is a solution – paying tips through QR codes. This is precisely why the
				VivatPay service was developed.
			</div>

			<Section2 />

			<div className='waiters-text'>
				Our service can be used by both private drivers and taxi companies.
				Drivers simply need to register in the system as "Tip Recipient" and can
				start working immediately. For owners or managers of the companies,
				there is an "Administrator" role that allows them to monitor the amount
				of incoming money, withdrawals, and assess the quality of work of each
				employee.
			</div>
			<Section3 />
			<Section4 />
			<Section9 />
			<Section10 />
			<Section11 />
		</>
	)
}

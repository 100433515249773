import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'

export const VerifyStep2 = ({ onNext, onBack }) => {
	useEffect(() => {
		const timer = setTimeout(() => {
			onNext()
		}, 2000)

		return () => clearTimeout(timer)
	}, [onNext])

	return (
		<div className='loading-overlay'>
			<CircularProgress sx={{ color: '#07D2FD' }} />
		</div>
	)
}

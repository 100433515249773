import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section11 } from '../../components-2/HomeComponents/HomeSection11/section11'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'
import logo from '../../images-2/section6Logo.svg'
import './style.css'
export const ForStaffWaiters = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='staff-section1'>
				<div className='staff-section1-block'>
					<div className='staff-section1-title'>Tips for Waiters</div>
					<div className='staff-section1-text'>
						Thanks to the VivatPay service, guests can leave tips for waiters in
						cafes or restaurants, as well as for bartenders, using their cards.
						Now, clients can express their gratitude even if they don’t have
						cash on hand. Only smartphone is required; clients do not need to
						install any additional apps. By registering in our system, you will
						be able to receive more tips and work with pleasure.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>

			<section className='waiters-description'>
				<div className='waiters-title'>
					How and When to Leave Tips for Waiters
				</div>
				<div className='waiters-text mt24px'>
					Tips are a significant addition to the low official salary of waiters.
					Therefore, a decrease in cash flow negatively affects both the income
					of cafe employees and the quality of their work. This, in turn, drives
					regular customers away from the place and leads to a decline in
					overall profits.
				</div>

				<div className='waiters-text mt24px'>
					Customers can express their gratitude to a waiter in the form of a
					small sum for:
				</div>

				<div className='waiters-text mt24px'>
					<ul>
						<li className='mt24px'>
							Polite and pleasant service, engaging conversation;
						</li>
						<li className='mt24px'>
							Advice and recommendations when choosing dishes;
						</li>
						<li className='mt24px'>
							Assistance that is not part of the main services;
						</li>
						<li className='mt24px'>
							The speed of service and attractive presentation;
						</li>
						<li className='mt24px'>Personal fondness for the staff member.</li>
					</ul>
				</div>

				<div className='waiters-text mt24px'>
					In cafes, tips typically amount to 5-15% of the bill, but can also
					reach 20-30% if the waiter has made a significant effort or provided
					unusual help. The minimum percentage indicates that everything was
					just okay. It is generally considered that the smallest amount a
					visitor can give to the staff as a tip is 1 euro, but it all depends
					on personal preferences.
				</div>

				<div className='waiters-text mt24px'>
					As cash is used less frequently, even well-meaning café customers may
					not always be able to leave tips. The VivatPay service aims to solve
					this problem by allowing customers to pay tips for the establishment's
					staff via a QR code. Customers will only need a minute for all the
					actions involved.
				</div>
			</section>

			<Section2 />

			<div className='waiters-text mt24px'>
				When registering, the waiter only needs to select the role of "Tip
				Recipient" or join the service by invitation from the "Administrator" –
				the owner of the institution. After that, they can present customers
				with a unique QR code and receive monetary gratitude. Meanwhile, the
				supervising staff will be able to see the amount of incoming funds and
				evaluate the employee's effectiveness without having access to the
				money; it will go to those who earned the tips.
			</div>

			<Section3 />
			<Section4 />
			<Section9 />

			<section className='staff-section1'>
				<img src={logo} alt='' />

				<div className='staff-section1-block'>
					<div className='staff-section1-title-2'>
						Integration with cash register systems
					</div>
					<div className='staff-section1-text-2'>
						Our service easily connects to your restaurant's automation system.
						This allows you to print employee QR codes directly on the receipts.
						Our technical support staff will help you set up the plugin for
						integration.
					</div>
				</div>
			</section>
			<Section10 />

			<Section11 />
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import card1 from '../../../images-2/section8Card1.svg'
import card2 from '../../../images-2/section8Card2.svg'
import card3 from '../../../images-2/section8Card3.svg'
import card4 from '../../../images-2/section8Card4.svg'
import card5 from '../../../images-2/section8Card5.svg'
import card6 from '../../../images-2/section8Card6.svg'
import './section8.css'
export const Section8 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section8'>
				<div className='section8-flex-block'>
					<div className='section8-title'>{t('section8-title')}</div>

					<div class='section8-card'>
						<div class='section8-card-item'>
							<div>{t('section8-card1')}</div>
							<img src={card1} alt='' />
						</div>
						<div class='section8-card-item'>
							<div>{t('section8-card2')}</div>
							<img src={card2} alt='' />
						</div>
						<div class='section8-card-item'>
							<div>{t('section8-card3')}</div>
							<img src={card3} alt='' />
						</div>
						<div class='section8-card-item'>
							<div>{t('section8-card4')}</div>
							<img src={card4} alt='' />
						</div>
						<div class='section8-card-item'>
							<div>{t('section8-card5')}</div>
							<img src={card5} alt='' />
						</div>
						<div class='section8-card-item'>
							<div>{t('section8-card6')}</div>
							<img src={card6} alt='' />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

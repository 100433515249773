import React from 'react'
import { Section10 } from '../../../components-2/HomeComponents/HomeSection10/section10'
import { Section11 } from '../../../components-2/HomeComponents/HomeSection11/section11'
import { Section9 } from '../../../components-2/HomeComponents/HomeSection9/section9'
import logo1 from '../../../images-2/howSection2Icon.svg'
import logo2 from '../../../images-2/howSection2Icon2.svg'
import logo3 from '../../../images-2/section3Payment.svg'
import logo4 from '../../../images-2/section4Logo.svg'

import './style.css'

export const HowSection2 = () => {
	return (
		<>
			<section className='how-section2'>
				<div className='how-section2-title'>
					Registration in the tipping service VivatPay takes just a couple of
					minutes, and you can start receiving tips right away.
				</div>

				<div className='register-block'>
					<img src={logo1} alt='' />

					<div className='register-block-description'>
						<div className='register-block-title'>1. Registration</div>
						<div className='register-block-text'>
							When you register, you receive a personal QR code. Once registered
							as an administrator, you can connect multiple people to the system
							at once.
						</div>
					</div>
				</div>

				<div className='qr-code-block'>
					<div className='qr-code-descr'>
						<div className='qr-code-title'>2. QR Code</div>
						<div className='qr-code-text'>
							To collect tips, you need to show your personal QR code to the
							guest. You can display it on your phone screen or print it in a
							convenient format.
						</div>
						<div className='qr-code-title-2'>Printed Materials</div>
						<div className='qr-code-text'>
							In your personal account, you can download or order the delivery
							of ready-made personalized templates (business cards, stickers,
							table tents). Just download or order delivery.
						</div>
					</div>

					<img src={logo2} alt='' />
				</div>

				<div className='qr-code-block'>
					<img src={logo3} alt='' />

					<div className='qr-code-descr'>
						<div className='qr-code-title'>3. Payment</div>
						<div className='qr-code-text'>
							The guest or client scans the QR code and is directed to your
							personal page, where they can leave a tip. The payment process
							takes about a minute.
						</div>
						<div className='qr-code-title-2'>How Payments are Made</div>
						<div className='qr-code-text'>
							Guests and clients pay electronic tips using a card or through
							payment systems via the QR code. All that is needed for payment is
							a smartphone; no apps need to be installed.
						</div>
					</div>
				</div>

				<div className='qr-code-block'>
					<img src={logo4} alt='' />

					<div className='qr-code-descr'>
						<div className='qr-code-title'>4. Withdrawal of Funds</div>
						<div className='qr-code-text'>
							Money is withdrawn instantly; you only need to provide your card
							number.
						</div>
						<div className='qr-code-title-2'>Instant Withdrawal</div>
						<div className='qr-code-text'>
							The system is fully automated; you won't have to wait.
						</div>
					</div>
				</div>

				<Section9 />
				<Section10 />
				<Section11 />
			</section>
		</>
	)
}

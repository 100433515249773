import { TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import logo2 from '../../images-2/loginLogo2.svg'

export const PersonalAccount = () => {
	const { register, handleSubmit, setValue } = useForm()

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('formData'))
		if (storedData) {
			setValue('firstName', storedData.firstName || '')
			setValue('lastName', storedData.lastName || '')
			setValue('phoneNumber', storedData.phoneNumber || '')
			setValue('email', storedData.email || '')
			setValue('city', storedData.city || '')
			setValue('trademark', storedData.trademark || '')
			setValue('tin', storedData.tin || '')
			setValue('address', storedData.address || '')
		}
	}, [setValue])

	const onSubmit = data => {
		console.log(data)
	}

	return (
		<>
			<section className='personalAccount'>
				<div className='account-block1'>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className='registerForm'>
							<div className='first-input'>
								<TextField
									{...register('firstName', { required: true })}
									label='First Name'
									variant='standard'
									sx={{ width: '100%' }}
								/>
								<TextField
									{...register('lastName', { required: true })}
									label='Last Name'
									variant='standard'
									sx={{ width: '100%' }}
								/>
							</div>

							<TextField
								{...register('phoneNumber', { required: true })}
								label='Phone Number'
								variant='standard'
								sx={{ width: '100%' }}
							/>

							<TextField
								{...register('email', { required: true })}
								label='E-mail'
								variant='standard'
								sx={{ width: '100%' }}
							/>

							<TextField
								{...register('city')}
								label='City'
								variant='standard'
								sx={{ width: '100%' }}
							/>

							<TextField
								{...register('trademark')}
								label='Trademark (Brand)'
								variant='standard'
								sx={{ width: '100%' }}
							/>

							<TextField
								{...register('tin')}
								label='Tax Identification Number (TIN)'
								variant='standard'
								sx={{ width: '100%' }}
							/>

							<TextField
								{...register('address')}
								label='Address'
								variant='standard'
								sx={{ width: '100%' }}
							/>
						</div>
					</form>
				</div>
				<div className='account-block2'>
					<NavLink to='verify'>
						<div className='btn-block-login'>
							<button className='btn-green account-btn'>Verify account</button>
							<img className='login-logo2' src={logo2} alt='' />
						</div>
					</NavLink>
					<div className='account-block2-text'>
						You need to verify your account to accept tips.
					</div>
				</div>
			</section>
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import image from '../../../images-2/howStartNewLogo1Section.svg'
import './style.css'
export const HowSection1 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='how-section1'>
				<div className='how-section1-block'>
					<div className='how-section1-title'>{t('how-section1-title')}</div>
					<div className='how-section1-text'>{t('how-section1-text1')}</div>
					<div className='how-section1-text'>{t('how-section1-text2')}</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={image} alt='' />
			</section>
		</>
	)
}

import React from 'react'
import { StaffSection1 } from '../../components-2/ForStaffComponents/ForStaffSection1/staffSection1'
import { StaffSection2 } from '../../components-2/ForStaffComponents/StaffSection2/StaffSection2'
import { Section11 } from '../../components-2/HomeComponents/HomeSection11/section11'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'

export const ForStaff = () => {
	return (
		<>
			<StaffSection1 />
			<StaffSection2 />
			<Section3 />
			<Section4 />
			<Section9 />
			<Section11 />
		</>
	)
}

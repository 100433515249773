import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import item2 from '../../../images-2/section11Item2.svg'
import item1 from '../../../images-2/section11Logo.svg'
import './section11.css'

export const Section11 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section11'>
				<img src={item1} alt='' />
				<div className='section11-block'>
					<div className='section11-title'>{t('section11-block-title')}</div>
					<div className='section11-text'>{t('section11-block-text')}</div>
					<NavLink to='/registration/form'>
						<div className='block-btn'>
							<button className='btn-white'>{t('section11-block-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={item2} alt='' />
			</section>
		</>
	)
}

import { useTranslation } from 'react-i18next'
import item from '../../../images-2/section5Men.svg'
import item2 from '../../../images-2/section5Staff.svg'
import './section5.css'

export const Section5 = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='section5'>
				<div className='section5-block'>
					<div className='section5-title'>{t('section5-title')}</div>
					<div className='section5-text'>{t('section5-text')}</div>
				</div>

				<img src={item2} alt='' />
				<img src={item} alt='' />
			</section>
		</>
	)
}

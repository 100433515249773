import React from 'react'
import { NavLink } from 'react-router-dom'
import './policy.css'

export const Policy = () => {
	return (
		<>
			<div className='policy-container'>
				<section className='policy'>
					<div className='policy-title'>Privacy Policy</div>
				</section>
				<div className='policy-title-2'>
					1. Purpose and Scope of Privacy Policy
				</div>
				<div className='policy-text mt16px'>
					Safeguarding your personal information is a top priority of VivatPay,
					UAB (hereinafter referred to as the “Company”, “we”, or us). We are
					dedicated to protecting your privacy and ensuring that your personal
					data is handled in a transparent and lawful manner, in compliance with
					the regulations of the European Union (General Data Protection
					Regulation 2016/679) and the laws of the Republic of Lithuania.
				</div>
				<div className='policy-text mt16px'>
					This Privacy Policy applies to:
				</div>
				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>Your use of our Website;</li>
						<li>Your use of our Payment Initiation Services; and</li>
						<li>Your use of our VivatPay System;</li>
					</ul>
				</div>
				<div className='policy-text mt16px'>
					In order to offer our Payment Initiation Services to you, we collect
					personal data, which includes information gathered during any
					necessary application including, but not limited to Registration Form
					to access our Services.
				</div>
				<div className='policy-text mt16px'>
					In this Privacy Policy, we outline the types of personal data we
					gather and process concerning the Users and/or their representatives,
					if any, who submit a Registration Form and utilise our Payment
					Initiation Services, or who have subscribed to receive advertising or
					information from our Company.
				</div>
				<div className='policy-text mt16px'>
					Please note that certain terms used in this Privacy Policy have
					defined meanings. These terms are capitalized and these definitions as
					well as additional information can be found in our Terms and
					Conditions. Please also note that additional information might be also
					provided in our internal policies, contracts, and other documents
					provided by us to you before entering into mutual cooperation.
				</div>
				<div className='policy-text mt16px'>
					Please thoroughly review this Privacy Policy to comprehend our
					procedures concerning your personal data and how we intend to handle
					it.
				</div>
				<div className='policy-text mt16px'>
					By agreeing to this Policy, you explicitly authorize us to collect,
					process, utilize, and disclose your personal information as outlined
					herein.
				</div>
				<div className='policy-text mt16px'>
					Please note that we process your personal data only in the context of
					providing you with Payment Initiation Services. Such processing of
					your personal data is based only on obtaining your consent, our mutual
					contractual arrangements, and our legal obligations in accordance with
					General Data Protection Regulation 2016/679, the Payment Law of the
					Republic of Lithuania and/or other applicable laws of the Republic of
					Lithuania. You can withdraw your consent at any time by contacting us
					via e-mail indicated in section 13 of the Terms and Conditions.
				</div>
				<div className='policy-title-2'>2. The Types of Data We Collect</div>
				<div className='policy-text mt16px'>
					When you use our Website and apply for registration (submit a
					Registration form), you must provide the following data:
				</div>
				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>Last name, first name; </li>
						<li>Email address;</li>
						<li>Phone numbers;</li>
						<li>Year, month, date, and place of birth;</li>
						<li>Photo;</li>
						<li>Details of the document confirming identity;</li>
						<li>Bank card information;</li>
					</ul>
				</div>
				<div className='policy-text mt16px'>
					Please take note that before entering into cooperation and execution
					of agreements with you for the provision of Payment Initiation
					Services, We may request you to provide us with additional information
					in accordance with this Privacy Policy, our internal policies,
					contracts, and/or other documents provided by us to you before
					entering into cooperation. All personal data, if any, provided under
					the mentioned documents are processed in accordance with this Privacy
					Policy.
				</div>
				<div className='policy-text mt16px'>
					Additionally, the website collects and processes anonymised data about
					visitors (including "cookie" files) using internet statistics services
					(Google Analytics, and others). The above-mentioned data will
					hereinafter be collectively referred to in this Policy as Personal
					Data.
				</div>
				<div className='policy-text mt16px'>
					In respect of personal data, we collect and process the following
					categories of personal data:
				</div>
				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>
							Personal data: full name, date of birth, personal identification
							documents, residential address, contact details (telephone number,
							e-mail address, correspondence address);{' '}
						</li>
						<li>
							Financial data: bank account details, and transaction details
							which you are going to conduct through VivatPay System. In order
							to conduct Payment Initiation Services, we may handle certain
							personal data of your customers;
						</li>
						<li>
							Communication data: information gathered through various
							communication and technical channels such as data contained in
							messages, emails, etc.;
						</li>
						<li>
							Device data: this pertains to details about your computer or
							handheld device, such as the internet protocol (IP) address,
							computer operating system, browser, browser version, plug-ins,
							computer time zone settings, mobile device type, unique device
							identifier, mobile operating system, and version;
						</li>
					</ul>
				</div>
				<div className='policy-text mt16px'>
					We are authorized to gather and utilize this data as it is essential
					for us to establish a contractual agreement with you and deliver our
					services to you within that agreement. It is also necessary for us to
					adhere to our legal and regulatory commitments. Failure to collect and
					process this information will prevent us from offering our services to
					you.
				</div>
				<div className='policy-title-2'>
					3. Data Received from Third Parties
				</div>
				<div className='policy-text mt16px'>
					The majority of the data in our possession concerning you will either
					be provided directly by you or will be gathered during your
					utilization of our Website and VivatPay System. Nevertheless, we might
					also receive personal details and/or information from other sources,
					such as:
				</div>
				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>Financial institutions;</li>
						<li>Financial institutions;</li>
						<li>Referral partners, Administrator of the workplace;</li>
						<li>Other public sources.</li>
					</ul>
				</div>
				<div className='policy-title-2'>
					4. With Whom We May Share Your Personal Data
				</div>
				<div className='policy-text mt16px'>
					We may share your personal data to ensure the provision of our Payment
					Initiation Services to you with our partners and/or other parties
					involved in the performance of the Payment Initiation Services through
					the VivatPay System or in other cases, where necessary and/or required
					by law.
				</div>
				<div className='policy-text mt16px'>
					We ensure that personal data is not disclosed beyond what is essential
					for the provision of Payment Initiation Services. We will not sell,
					distribute, or lease your personal data to other third parties, except
					for external service providers such as: banking partners, technical
					service providers, payment card issuers, payment service providers,
					and/or other financial institutions.
				</div>
				<div className='policy-text mt16px'>
					Except for disclosing your personal data in the frame of provision of
					Payment Initiation Services to you, we may also disclose your personal
					data to the following recipients:
				</div>
				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>Law enforcement authorities; </li>
						<li>Courts;</li>
						<li>Our affiliates;</li>
						<li>Our auditors;</li>
						<li>
							Other our contractors involved in the provision of services.
						</li>
					</ul>
				</div>
				<div className='policy-title-2'>
					5. Transfer of Personal Data to Third Countries
				</div>
				<div className='policy-text mt16px'>
					We are committed to safeguarding your personal data and ensuring that
					it is handled securely and in compliance with our Privacy Policy. Your
					Personal Data will only be transferred to organizations or countries
					with adequate controls in place to protect your information, as
					outlined in this Policy.
				</div>
				<div className='policy-text mt16px'>
					In order to provide our Payment Initiation Services, your personal
					data may need to be shared with companies located outside of the
					EU/EEA countries. When transferring data to third countries, we uphold
					applicable laws to ensure a sufficient level of data protection. We
					comply with the standard requirements of General Data Protection
					Regulation 2016/679 to maintain adequate safeguards for the
					international transfer of your data and oblige our affiliates, service
					providers, and/or other partners to comply with this General Data
					Protection Regulation 2016/679 as well.
				</div>
				<div className='policy-title-2'>6. How We Store Your Personal Data</div>

				<div className='policy-text mt16px'>
					Security is a top priority in the provision of our Payment Initiation
					Services. We have established various organizational and technical
					measures to protect your personal data from accidental or unlawful
					destruction, alteration, disclosure, and any other unauthorized
					processing. While we make every effort to use commercially acceptable
					methods to safeguard your personal data, we cannot guarantee its
					complete security.
				</div>

				<div className='policy-title-2'>7. What are your rights</div>
				<div className='policy-text mt16px'>
					You may have the following rights with regard to your personal data
					that is prescribed in General Data Protection Regulation 2016/679:
				</div>

				<div className='policy-text mt16px'>
					<ul className='policy-ul'>
						<li>
							Accessing and updating information. If you find that any of the
							information, we have is incorrect or incomplete, you have the
							right to have it rectified. You can request a copy of the
							information we hold about you, correct it, and send us updated
							information or you can ask us to correct any inaccuracies that you
							are unable to update yourself. We will also inform our partners
							who have shared your data about updates in your data. Also, you
							have the right at any time to request and receive your personal
							data that we are processing.{' '}
						</li>
						<li>
							Right to object. You have the right to object to the processing of
							your personal data that is based on legitimate interests or the
							performance of tasks in the public interest of official authority,
							unless We have strong justifiable reasons to proceed with
							processing your data or if the processing is necessary for the
							establishment, exercise, or defense of legal claims or for other
							legitimate reasons. You have the right to object to the processing
							of your personal data at any time, we utilize your personal data
							for sending advertising or information from our Company.
						</li>
						<li>
							The right to be informed. You have the right to request and to be
							provided with clear and transparent information about the
							processing of your data.
						</li>
						<li>
							The right of access. You can contact us via e-mail indicated in
							section 13 of the Terms and Conditions and request us to provide
							you with access to your personal data. We will grant you access to
							your personal data along with the following details: the
							processing purposes, the types of personal data we collected, the
							partners with whom we shared your personal data, the timeframe for
							retaining or expected retention period of the data, the origin of
							the data if we received from third parties as indicated in Section
							4 of this Policy.{' '}
						</li>
						<li>
							The right to restrict processing. You have the right to request us
							not to process your Personal Data in the following situations:  -
							If the accuracy of the data is being disputed;  - If the data is
							no longer needed for its original purpose but you require it to
							establish, exercise, or defend a legal claim.  - If the data has
							been processed unlawfully and you prefer a restriction on
							processing instead of deletion;  - If you have objected to the
							processing of your data and we are evaluating if there are
							overriding legitimate grounds to continue processing.
						</li>
						<li>
							The right to be forgotten. You have the right to request to delete
							your personal data, if:  - there is no longer an original purpose
							for which we collected your personal data, and/or a legal basis or
							legitimate reason for us to further process your personal data;  -
							the data has been unlawfully processed;  - it is necessary to
							comply with General Data Protection Regulation 2016/679 and the
							laws of the Republic of Lithuania.
						</li>
					</ul>

					<div className='policy-title-2'>8. Complaints </div>

					<div className='policy-text mt16px'>
						If you have any complaints, comments, or questions about this
						Policy, you can contact us via e-mail indicated in section 13 of the{' '}
						<NavLink to='/terms' className='terms'>
							Terms and Conditions.
						</NavLink>
					</div>

					<div className='policy-text mt16px'>
						If you believe that your rights under the General Data Protection
						Regulation 2016/679 have been violated, you have the right to file a
						complaint with the appropriate supervisory authority in Lithuania
						(State Data Protection Inspectorate).
					</div>

					<div className='policy-title-2'>9. Privacy policy review</div>

					<div className='policy-text mt16px'>
						We may change or revise this Privacy Policy at any time. Any updates
						or amendments to the Privacy Policy will be effective upon
						publication on this page. It is recommended that you periodically
						review the Privacy Policy to ensure that you are comfortable with
						the current version. The last updated date will be displayed at the
						end of this Policy.
					</div>

					<div className='policy-text mt16px'>Last updated: 18 April 2024.</div>
				</div>
			</div>
		</>
	)
}

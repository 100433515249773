import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
} from '@mui/material'
import React, { useState } from 'react'
import './faq.css'

const faqData = [
	{
		question:
			'How does the integration of the QR code turn into the pre-check process?',
		answer: 'The integration of the QR code into the pre-check occurs by installing a special plugin for your automation system. Our plugin works with ІІКО and R-keeper. To install the plugin, simply fill out the application in your personal account after registering your account in the Company ⇨ Integrations section.',
	},
	{
		question: 'How are funds withdrawn from the service?',
		answer:
			'To withdraw funds from the service, you need to log into your personal account on the website, go to the “Transfers” ⇨ “Withdrawal to Card” section, and fill out the card details along with the withdrawal amount. The funds will be credited to your card within a minute.',
	},
	{
		question: 'Service Rates of VivatPay',
		answer: 'A single rate applies to all users of the service - always 0%. There are no monthly fees or additional payments.',
	},
	{
		question: 'How can I get business cards with a QR code?',
		answer: 'After registering with the service, you can instantly receive templates for business cards, stickers, and cards with your QR code and send them for printing. Additionally, you can order ready-made printed materials through the “Printing” ⇨ “Print Materials with Delivery” section. The address and method of delivery will be confirmed by the manager after placing the order.',
	},
	{
		question: 'Can I transfer my tips to a colleague?',
		answer: 'In the VivatPay service, you can transfer tips to your colleagues without any commission and without leaving your personal account. To do this, go to the “Transfers” ⇨ “User” section, enter the unique 9-digit user number, specify the transfer amount, and in an instant, the money will be on the recipient`s account.',
	},
	{
		question: 'What is the minimum amount for withdrawing money?',
		answer: 'You can withdraw any amount of tips to any bank card. There is no commission for the transfer.',
	},
]

const FaqComponent = () => {
	const [expanded, setExpanded] = useState(false)

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return (
		<Box sx={{ width: '100%', margin: '0 auto', marginBottom: '50px' }}>
			<div className='faq-title'>Q&A</div>
			{faqData.map((item, index) => (
				<Accordion
					key={index}
					expanded={expanded === index}
					onChange={handleChange(index)}
					sx={{
						marginBottom: '12px',
						borderRadius: '16px',
						boxShadow:
							expanded === index
								? '0px 4px 40px 0px rgba(0, 0, 0, 0.10)'
								: '0 4px 12px rgba(0, 0, 0, 0.1)',
						'&:before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel${index}-content`}
						id={`panel${index}-header`}
						sx={{
							minHeight: '80px',
							background:
								expanded === index
									? 'linear-gradient(323deg, #1183C3 19.51%, #07D2FD 80.8%)'
									: '#fff',
							color: expanded === index ? '#fff' : '#000',
							borderRadius: '16px',
							'& .MuiAccordionSummary-content': {
								margin: '8px 0',
							},
						}}
					>
						<div className='question'>{item.question}</div>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							padding: '24px',
							minHeight: '80px',
						}}
					>
						<div className='answer'>{item.answer}</div>
					</AccordionDetails>
				</Accordion>
			))}
		</Box>
	)
}

export default FaqComponent

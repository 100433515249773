const header = {
	About: 'О компании',
  'How Start': 'Как начать',
  'For Staff': 'Для сотрудников',
  'For Business': 'Для бизнеса',
  Start: 'Начать',
}

export const ru = {
	...header,
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { BusinessSection } from '../../components-2/BusinessComponents/BusinessSection/BusinessSection'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section7 } from '../../components-2/HomeComponents/HomeSection7/section7'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'
import logo from '../../images-2/section6Logo.svg'
import './style.css'
export const BusinessRestor = () => {
	const { t } = useTranslation()

	return (
		<>
			<section className='business-section1'>
				<div className='business-section1-block'>
					<div className='business-section1-title'>
						Tips via QR Code in Restaurants
					</div>
					<div className='business-section1-text'>
						With the VivatPay service, your restaurant staff will be able to
						receive tips directly from customers' cards. A customer needs a
						smartphone to do it. To leave a tip, the client simply scans the
						employee's personal QR code and makes the payment. This takes just
						few seconds. By implementing our electronic tipping system in your
						institution, you can enhance the service.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>

			<section className='business-descr-rest'>
				<div className='descr-title'>The Role of Tips in Restaurants</div>

				<div className='descr-text mt24px'>
					For owners of catering establishments—cafes, restaurants, bistros,
					etc.—tips are not a source of income and, with rare exceptions,
					provide little to no profit. However, for waiters, bartenders, and
					other staff, tips are an important bonus that can significantly exceed
					their official salary. The size and regularity of monetary gratitude
					depend on several factors:
				</div>
				<div className='descr-text mt24px'>
					Customers can express their gratitude to a waiter in the form of a
					small sum for:
				</div>
				<div className='descr-text mt24px'>
					<ul>
						<li className='mt24px'>
							Quality of services provided by staff—politeness, friendliness,
							and the ability to interact well with clients, which helps to form
							regular customers;
						</li>
						<li className='mt24px'>
							Professionalism—profitable positions attract specialists and
							individuals willing to work hard and showcase their skills;
						</li>
						<li className='mt24px'>
							Attractiveness of the institution—the better the service, the more
							well-known the restaurant becomes, and as a result, revenue
							increases;
						</li>
						<li className='mt24px'>
							Team cohesion and its readiness to promptly fulfill guests'
							wishes, which also positively affects the status of the cafe;
						</li>
					</ul>
				</div>

				<div className='descr-text mt24px'>
					However, cash turnover is gradually decreasing, along with the amount
					of tips. The decline in restaurant staff incomes leads them to seek
					employment in more profitable institutions, which is detrimental to
					owners. To avoid this, new methods for transferring money for tips to
					staff must be implemented. One of the methods is paying tips via QR
					code through the VivatPay service. The only requirement for its use is
					that both waiters and guests need a smartphone.
				</div>
				<div className='descr-text mt24px'>
					As "Administrators," institution owners will have access to
					information about incoming funds and withdrawals when using our
					system. This data allows for easy evaluation and monitoring of staff
					performance, aiding in the development of more effective strategies
					for customer service and interaction.
				</div>
			</section>

			<BusinessSection />

			<section className='title2'>
				Guests often do not have cash or small denomination banknotes. With our
				service, your staff can accept tips from guests via card or through
				payment systems using QR codes.
			</section>

			<section className='descr'>
				<div>
					<div className='descr-title'>No Costs</div>
					<div className='descr-text'>
						The service is free for owners: we only take a commission from tips,
						with no additional payments. All accounting and legal matters are
						handled by us.
					</div>
				</div>
				<div>
					<div className='descr-title'>Easy to Connect</div>
					<div className='descr-text'>
						Registration takes just a couple of minutes, and there’s no need to
						install any apps. You can start receiving tips and withdrawing money
						immediately.
					</div>
				</div>
			</section>

			<Section2 />
			<Section3 />
			<Section9 />
			<Section4 />
			<Section10 />
			<Section7 />
			<div className='mb50px'></div>
		</>
	)
}

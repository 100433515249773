import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import logo from '../../images-2/loginLogo.svg'
import logo2 from '../../images-2/loginLogo2.svg'
import './login.css'

export const Login = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const [loginError, setLoginError] = useState('')
	const navigate = useNavigate()
	const onSubmit = data => {
		const storedData = JSON.parse(localStorage.getItem('formData'))
		if (
			storedData &&
			storedData.phoneNumber === data.phoneNumber &&
			storedData.password === data.password
		) {
			setLoginError('')
			navigate('/account')
		} else {
			setLoginError('Phone number or password is incorrect')
		}
	}

	return (
		<>
			<section className='login-container'>
				<div className='login-logo'>
					<img src={logo} alt='' />
				</div>
				<form className='login-form' onSubmit={handleSubmit(onSubmit)}>
					<div className='login-form-title'>Login</div>
					<div className='input-block'>
						<TextField
							{...register('phoneNumber', {
								required: 'Phone Number is required',
							})}
							label='Phone Number'
							variant='standard'
							sx={{ width: '100%', marginBottom: '24px' }}
							error={!!errors.phoneNumber}
							helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
						/>
						<TextField
							{...register('password', { required: 'Password is required' })}
							label='Password'
							variant='standard'
							type='password'
							sx={{ width: '100%', marginBottom: '24px' }}
							error={!!errors.password}
							helperText={errors.password ? errors.password.message : ''}
						/>
					</div>
					{loginError && (
						<div style={{ color: 'red', marginBottom: '20px' }}>
							{loginError}
						</div>
					)}{' '}
					<div className='btn-block-login'>
						<button type='submit' className='btn-blue'>
							Login
						</button>
						<img className='login-logo2' src={logo2} alt='' />
					</div>
					<div className='complete'>
						<div className='complete-text'>
							Are you with us for the first time? Complete{' '}
							<NavLink to='/registration'>
								<span>a quick registration.</span>
							</NavLink>
						</div>
					</div>
				</form>
			</section>
		</>
	)
}

import CircularProgress from '@mui/material/CircularProgress'
import React, { useState } from 'react'
import img from '../../images-2/verifyStep1Img.svg'
import img2 from '../../images-2/verifyStep1Img2.svg'
import './account.css'

export const VerifyStep1 = ({ onNext }) => {
	const [file, setFile] = useState(null)
	const [stepPhoto, setStepPhoto] = useState(false)
	const [loading, setLoading] = useState(false)

	const goToStepPhoto = () => {
		setLoading(true)
		setTimeout(() => {
			setStepPhoto(true)
			setLoading(false)
		}, 1000)
	}

	const handleFileChange = event => {
		const selectedFile = event.target.files[0]
		if (selectedFile) {
			goToStepPhoto()
		}
	}

	const handleFileChange2 = event => {
		const selectedFile = event.target.files[0]
		if (selectedFile) {
			setFile(selectedFile)
			onNext()
		}
	}

	return (
		<>
			{loading && (
				<div className='loading-overlay'>
					<CircularProgress sx={{ color: '#07D2FD' }} />
				</div>
			)}

			{!stepPhoto && !loading && (
				<section className='step1'>
					<div className='step1-block'>
						<div className='step1-block-left'>
							<div className='block-text'>
								Type of identity document you wish to validate
							</div>
							<input
								type='file'
								accept='image/*'
								onChange={handleFileChange}
								className='file-input'
							/>
							<button
								type='button'
								className='btn-green-account'
								onClick={() => document.querySelector('.file-input').click()}
							>
								Upload file
							</button>
						</div>
						<div className='step1-block-right'>
							<img src={img} alt='' />
							<p>
								Ensure the full document is visible including the signature.
							</p>
						</div>
					</div>
				</section>
			)}

			{stepPhoto && !loading && (
				<section className='step1'>
					<div className='step1-block'>
						<div className='step1-block-left'>
							<div className='block-text'>
								Please take a photo where your face is clearly visible. Make
								sure:
								<ul>
									<li>Your face is well-lit and centred in the frame;</li>
									<li>You are not wearing sunglasses or a hat;</li>
									<li>You are looking directly at the camera.</li>
								</ul>
							</div>
							<input
								type='file'
								accept='image/*'
								onChange={handleFileChange2}
								className='file-input'
							/>
							<button
								type='button'
								className='btn-green-account'
								onClick={() => document.querySelector('.file-input').click()}
							>
								Upload photo
							</button>
						</div>
						<div className='step1-block-right'>
							<img src={img2} alt='' />
						</div>
					</div>
				</section>
			)}
		</>
	)
}

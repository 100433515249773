import React from 'react'
import logoCheck from '../../../images-2/businessLogo2.svg'
import './style.css'
export const BusinessSection = () => {
	return (
		<>
			<section className='integration-section'>
				<div className='integration-content'>
					<div className='text-content'>
						<h2>Integration with Cash Register Systems</h2>
						<p>
							Our service easily connects to your restaurant's automation
							system.
						</p>
						<p>
							This allows you to print employee QR codes directly on the
							receipts.
						</p>
						<p>
							Our support team will help you set up the plugin for integration.
						</p>
					</div>
					<div className='image-content'>
						<img src={logoCheck} alt='Receipt with QR code' />
					</div>
				</div>
			</section>
		</>
	)
}

import {
	Dialog,
	DialogContent,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useState } from 'react'
import imgClose from '../../images-2/imgClose.svg'
import './transaction.css'

export const Transaction = () => {
	const [transactionType, setTransactionType] = useState('all')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [open, setOpen] = useState(false)

	const handleTransactionTypeChange = event => {
		setTransactionType(event.target.value)
	}

	// Функция открытия диалога
	const handleClickOpen = () => {
		setOpen(true)
	}

	// Функция закрытия диалога
	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
			<section className='tran'>
				<div className='transactions-filter'>
					<h2 className='title-transactions'>Transactions</h2>

					<div className='type-block'>
						<div className='transaction-type'>
							<label>Type of transaction</label>

							<RadioGroup
								row
								value={transactionType}
								onChange={handleTransactionTypeChange}
							>
								<FormControlLabel value='all' control={<Radio />} label='All' />
								<FormControlLabel
									value='incoming'
									control={<Radio />}
									label='Incoming'
								/>
								<FormControlLabel
									value='withdrawal'
									control={<Radio />}
									label='Withdrawal'
								/>
							</RadioGroup>
						</div>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<div className='transaction-period'>
								<DatePicker
									value={startDate}
									onChange={newValue => setStartDate(newValue)}
									renderInput={params => <TextField {...params} />}
								/>
								<DatePicker
									value={endDate}
									onChange={newValue => setEndDate(newValue)}
									renderInput={params => (
										<TextField
											InputLabelProps={{
												shrink: true,
											}}
											{...params}
										/>
									)}
									InputLabelProps={{ shrink: true }}
								/>
							</div>
						</LocalizationProvider>
					</div>
				</div>

				<div className='transactions-section'>
					<table className='transactions-table'>
						<thead>
							<tr>
								<th>#</th>
								<th>Sum</th>
								<th>Type</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr>
								<td>-</td>
								<td>-</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className='payouts-section'>
					<h2 className='title-transactions'>Payouts</h2>
					<table className='payouts-table'>
						<thead>
							<tr>
								<th>Name</th>
								<th>To-date</th>
								<th>Last</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<button className='btn-tran' onClick={handleClickOpen}>
										Pay
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</section>

			<Dialog
				open={open}
				onClose={handleClose}
				sx={{ '& .MuiDialog-paper': { width: '500px', padding: '20px' } }}
			>
				<div className='dialog-img-close'>
					<img src={imgClose} alt='' onClick={handleClose} />
				</div>
				<DialogContent>
					<div className='dialog-title'>Payout</div>
					<div className='dialog-text'>You are about to withdraw your tips.</div>
					<div className='dialog-input'>
						<TextField
							label='Enter Amount'
							variant='standard'
							sx={{ width: '100%' }}
						/>
					</div>
					<div className='dialog-text'>Available balance: 0.00€</div>
					<div className='dialog-btn'>
						<button className='btn-green' onClick={handleClose}>
							Initiate Payment
						</button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

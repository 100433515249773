import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	TextField,
} from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import backIcon from '../../images-2/backIcon.svg'
import logo from '../../images-2/loginLogo.svg'
import logo2 from '../../images-2/loginLogo2.svg'
import toast from 'react-hot-toast'
export const RegisterForm = () => {
	const {
		register,
		handleSubmit,
		onError,
		formState: { errors },
	} = useForm()

	const navigate = useNavigate()

	const onSubmit = data => {
		localStorage.setItem('formData', JSON.stringify(data))
		toast.success('Registration successful')
		navigate('/login')
	}
	return (
		<>
			<form
				className='register-form'
				onSubmit={handleSubmit(onSubmit, onError)}
			>
				<div>
					<img src={logo} alt='' />
				</div>
				<div className='registerForm'>
					<div className='register-form-title'>Registration</div>
					<div className='first-input'>
						<TextField
							{...register('firstName', { required: true })}
							label='First Name'
							variant='standard'
							sx={{ width: '100%' }}
							error={!!errors.firstName}
							helperText={errors.firstName ? 'First Name is required' : ''}
						/>
						<TextField
							{...register('lastName', { required: true })}
							label='Last Name'
							variant='standard'
							sx={{ width: '100%' }}
							error={!!errors.lastName}
							helperText={errors.lastName ? 'Last Name is required' : ''}
						/>
					</div>

					<TextField
						{...register('phoneNumber', { required: true })}
						label='Phone Number'
						variant='standard'
						sx={{ width: '100%' }}
						error={!!errors.phoneNumber}
						helperText={errors.phoneNumber ? 'Phone Number is required' : ''}
					/>

					<TextField
						{...register('email', { required: true })}
						label='E-mail'
						variant='standard'
						sx={{ width: '100%' }}
						error={!!errors.email}
						helperText={errors.email ? 'Email is required' : ''}
					/>

					<TextField
						{...register('city')}
						label='City'
						variant='standard'
						sx={{ width: '100%' }}
					/>

					<TextField
						{...register('trademark')}
						label='Trademark (Brand)'
						variant='standard'
						sx={{ width: '100%' }}
					/>

					<TextField
						{...register('tin')}
						label='Tax Identification Number (TIN)'
						variant='standard'
						sx={{ width: '100%' }}
					/>

					<TextField
						{...register('address')}
						label='Address'
						variant='standard'
						sx={{ width: '100%' }}
					/>

					<TextField
						{...register('password', { required: true })}
						label='Password'
						variant='standard'
						type='password'
						sx={{ width: '100%' }}
						error={!!errors.password}
						helperText={errors.password ? 'Password is required' : ''}
					/>

					<TextField
						{...register('repeatPassword', { required: true })}
						label='Repeat Password'
						type='password'
						variant='standard'
						sx={{ width: '100%' }}
						error={!!errors.repeatPassword}
						helperText={
							errors.repeatPassword ? 'Repeat Password is required' : ''
						}
					/>

					<Box className='account-type-container'>
						<div>Account Type</div>

						<RadioGroup row>
							<FormControlLabel
								control={
									<Radio
										color='success'
										{...register('accountType', { required: false })}
									/>
								}
								value='tipsRecipient'
								label='Tips Recipient'
							/>
							<FormControlLabel
								control={
									<Radio
										color='success'
										{...register('accountType', { required: false })}
									/>
								}
								value='administrator'
								label={<div className='admin-label'>Administrator</div>}
							/>
						</RadioGroup>

						<FormGroup className='checkbox-group'>
							<FormControlLabel
								control={
									<Checkbox
										color='success'
										{...register('consent', { required: false })}
									/>
								}
								label="I have read the Consent to the Processing of Personal Data and the VivatPay Privacy Policy. Checking the box 'V' means my written consent to the terms of these documents and my agreement to comply with them."
							/>
							<FormControlLabel
								control={
									<Checkbox
										color='success'
										{...register('termsOfUse', { required: false })}
									/>
								}
								label="I have read the Terms of Use. Checking the box 'V' means my written consent to the terms of the agreement and my agreement to comply with them."
							/>
						</FormGroup>
					</Box>

					<div className='btn-block-login'>
						<button type='submit' className='btn-green'>
							Register
						</button>
						<img className='login-logo2' src={logo2} alt='' />
					</div>

					<NavLink to='/login'>
						<div className='back-to-login'>
							<img src={backIcon} alt='' />
							<div>Login</div>
						</div>
					</NavLink>
				</div>
			</form>
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { BusinessSection } from '../../components-2/BusinessComponents/BusinessSection/BusinessSection'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section11 } from '../../components-2/HomeComponents/HomeSection11/section11'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'
import logo from '../../images-2/section6Logo.svg'
import './style.css'

export const Business = () => {
	const { t } = useTranslation()
	
	return (
		<>
			<section className='business-section1'>
				<div className='business-section1-block'>
					<div className='business-section1-title'>
						Cashless Tips for Your Business
					</div>
					<div className='business-section1-text'>
						When paying in a cashless way, the number of tips per shift reliably
						increases, which in turn boosts staff motivation.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>

			<section className='title2'>
				Registration in the tipping service VivatPay takes just a couple of
				minutes, and you can start receiving tips right away.
			</section>

			<section className='descr'>
				<div>
					<div className='descr-title'>No Costs</div>
					<div className='descr-text'>
						The service is free for owners: we only take a commission from tips,
						with no additional payments. All accounting and legal matters are
						handled by us.
					</div>
				</div>
				<div>
					<div className='descr-title'>Easy to Connect</div>
					<div className='descr-text'>
						Registration takes just a couple of minutes, and there’s no need to
						install any apps. You can start receiving tips and withdrawing money
						immediately.
					</div>
				</div>
			</section>

			<Section2 />
			<Section3 />
			<Section9 />
			<Section4 />
			<BusinessSection />
			<Section10 />
			<Section11 />
		</>
	)
}

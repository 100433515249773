import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Section10 } from '../../components-2/HomeComponents/HomeSection10/section10'
import { Section2 } from '../../components-2/HomeComponents/HomeSection2/Section2'
import { Section3 } from '../../components-2/HomeComponents/HomeSection3/section3'
import { Section4 } from '../../components-2/HomeComponents/HomeSection4/section4'
import { Section7 } from '../../components-2/HomeComponents/HomeSection7/section7'
import { Section9 } from '../../components-2/HomeComponents/HomeSection9/section9'
import logo from '../../images-2/section6Logo.svg'
import './style.css'
export const BusinessTaxi = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='business-section1'>
				<div className='business-section1-block'>
					<div className='business-section1-title'>Cashless tips for taxi</div>
					<div className='business-section1-text'>
						Do you want your customers to leave tips with a card in the taxi?
						With the VivatPay service, it's easy. Even if a customer has no cash
						left to thank the driver for the ride, they can do it using their
						smartphone. They can leave a tip by scanning your employee's
						personal QR code. You can enable this feature for all your drivers,
						making your service even better.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>

			<section className='business-descr-rest'>
				<div className='descr-title'>The Importance of Tips for Taxi</div>

				<div className='descr-text mt24px'>
					Tips for taxi drivers are a significant addition to their earnings as
					they are for other service industry workers. Taxi fleet owners do not
					receive this income, but the quality of the service provided and the
					popularity of the carrier among the public directly depend on them.
					Good cash and cashless tips motivate and encourage employees to work
					better, be polite and helpful, and assist customers.
				</div>
				<div className='descr-text mt24px'>
					Taxi drivers can also receive tips in other cases:
				</div>
				<div className='descr-text mt24px'>
					<ul>
						<li className='mt24px'>
							For quick response to a call – arriving earlier than expected;
						</li>
						<li className='mt24px'>
							For considering the passenger's needs and requirements;
						</li>
						<li className='mt24px'>
							For helping with loading and unloading luggage;
						</li>
						<li className='mt24px'>
							For providing support during boarding, especially if the person is
							elderly or has an injury;
						</li>
						<li className='mt24px'>
							For engaging in pleasant and unobtrusive conversation, sharing
							information about the city when asked;
						</li>
						<li className='mt24px'>
							For personal charm and courteous treatment.
						</li>
					</ul>
				</div>

				<div className='descr-text mt24px'>
					All of this impacts the attractiveness of the entire taxi fleet, as
					passengers are more likely to turn to a good and comfortable carrier.
					However, a decrease in cash flow affects the number of tips, even with
					top-notch service. As a consequence, driver motivation also declines.
					This problem can be avoided by integrating VivatPay and providing the
					option to leave tips via QR code. Each company driver will have their
					own individual code, which is easily scanned by a smartphone. The
					money transfer for the passenger takes less than a minute.
				</div>
				<div className='descr-text mt24px'>
					The owner of the fleet can log into the system as an "Administrator,"
					gaining access to detailed information about the number of tips and
					fund withdrawals. This data can be used to assess the quality of
					services.
				</div>
			</section>

			<section className='title2'>
				Guests often do not have cash or small denomination banknotes. With our
				service, your staff can accept tips from guests via card or through
				payment systems using QR codes.
			</section>

			<section className='descr'>
				<div>
					<div className='descr-title'>No Costs</div>
					<div className='descr-text'>
						The service is free for owners: we only take a commission from tips,
						with no additional payments. All accounting and legal matters are
						handled by us.
					</div>
				</div>
				<div>
					<div className='descr-title'>Easy to Connect</div>
					<div className='descr-text'>
						Registration takes just a couple of minutes, and there’s no need to
						install any apps. You can start receiving tips and withdrawing money
						immediately.
					</div>
				</div>
			</section>

			<Section2 />
			<Section3 />
			<Section9 />
			<Section4 />
			<Section10 />
			<Section7 />
			<div className='mb50px'></div>
		</>
	)
}

import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import { Footer2 } from './components-2/Footer/footer2'
import { Header2 } from './components-2/Header/Header2'
import { Login } from './components-2/Login/Login'
import { RegisterForm } from './components-2/Login/RegisterForm'
import { Registration } from './components-2/Login/Registration'
import { Policy } from './components-2/Policy/Policy'
import { Terms } from './components-2/Terms/Terms'
import './i18n'
import { About2 } from './pages-2/AboutPage2/About2'
import { Account } from './pages-2/Account/Account'
import { PersonalAccount } from './pages-2/Account/PersonalAccount'
import { Contact } from './pages-2/Contacts/Contact'
import { Business } from './pages-2/ForBusinessPage/Business'
import { BusinessRestor } from './pages-2/ForBusinessPage/BusinessRestor'
import { BusinessTaxi } from './pages-2/ForBusinessPage/BusinessTaxi'
import { ForStaffWaiters } from './pages-2/ForStaffPage2/FoStaffWaiters'
import { ForStaff } from './pages-2/ForStaffPage2/ForStaff'
import { ForStaffTaxi } from './pages-2/ForStaffPage2/ForStaffTaxi'
import { Home2 } from './pages-2/HomePage2/Home2'
import { HowStart } from './pages-2/HowStart/HowStart'
import { Verify } from './pages-2/Account/Verify'
import { Qr } from './pages-2/QR/Qr'
import { Transaction } from './pages-2/Transactions/Transaction'

function App() {
	return (
		<Router>
			<Toaster position='top-center' />
			<ScrollToTop />
			<div className='container'>
				<Header2 />
				<main className='main-content'>
					<Routes>
						<Route path='/' element={<Home2 />} />
						<Route path='/about' element={<About2 />} />
						<Route path='/how-start' element={<HowStart />} />
						<Route path='/for-staff' element={<ForStaff />} />
						<Route path='/for-staff-waiters' element={<ForStaffWaiters />} />
						<Route path='/for-staff/taxi' element={<ForStaffTaxi />} />
						<Route path='/for-business' element={<Business />} />
						<Route
							path='/for-business/restaurants'
							element={<BusinessRestor />}
						/>
						<Route path='/for-business/taxi' element={<BusinessTaxi />} />
						<Route path='/login' element={<Login />} />
						<Route path='/registration' element={<Registration />} />
						<Route path='/registration/form' element={<RegisterForm />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/policy' element={<Policy />} />
						<Route path='/terms' element={<Terms />} />

						<Route path='/account' element={<Account />}>
							<Route path='' element={<PersonalAccount />} />
							<Route path='verify' element={<Verify />} />
							<Route path='qr' element={<Qr />} />
							<Route path='transactions' element={<Transaction />} />
						</Route>
					</Routes>
					{/* <Routes>
						<Route path='/' element={<Home />} />
						<Route path='/register' element={<Register />} />
						<Route path='/borrow' element={<HowToBorrow />} />
						<Route path='/postpone' element={<HowToPostpone />} />
						<Route path='/faq' element={<FaqPages />} />
						<Route path='/about' element={<About />} />
						<Route path='/contact' element={<ContactUs />} />
						<Route path='/cancellation' element={<Cancellation />} />
						<Route path='/privacy-policy' element={<Policy />} />
						<Route path='/terms-and-conditions' element={<Term />} />
					</Routes> */}
				</main>
			</div>
			<Footer2 />
		</Router>
	)
}

export default App

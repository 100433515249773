import { Section2 } from '../../HomeComponents/HomeSection2/Section2'
import './style.css'

export const StaffSection2 = () => {
	return (
		<>
			<Section2 />
		</>
	)
}

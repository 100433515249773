import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import logo from '../../../images-2/section6Logo.svg'
import './style.css'
export const StaffSection1 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='staff-section1'>
				<div className='staff-section1-block'>
					<div className='staff-section1-title'>Accept tips from cards</div>
					<div className='staff-section1-text'>
						Receive tips from guests using cards. Registration takes just a
						minute, and money is withdrawn instantly to your card. If a client
						wants to leave a tip, they do not need to install any apps.
					</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={logo} alt='' />
			</section>
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../../images-2/section10Logo.svg'
import './section10.css'
export const Section10 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section10'>
				<div className='section10-title'>{t('section10-title')}</div>

				<div className='section10-block'>
					<img src={logo} alt='' />
					<div className='section10-block-description'>
						<p className='section10-block-title'>
							{t('section10-block-title')}
						</p>
						<p className='section10-block-text'>{t('section10-block-text')}</p>
					</div>
				</div>
			</section>
		</>
	)
}

import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../images-2/footer2.svg'
import icon1 from '../../images-2/footer2Icon1.svg'
import icon2 from '../../images-2/footer2Icon2.svg'
import icon3 from '../../images-2/footer2Icon3.svg'
import './footer2.css'

export const Footer2 = () => {
	return (
		<>
			<footer className='footer2'>
				<div className='footer-container-2'>
					<div className='icon-block'>
						<img src={logo} alt='' />
					</div>

					<div className='footer-list'>
						<NavLink to='/contact'>
							<div className='list-item'>Contacts</div>
						</NavLink>

						<NavLink to='/policy'>
							<div className='list-item'>Privacy Policy</div>
						</NavLink>

						<NavLink to='/terms'>
							<div className='list-item'>Terms and Conditions</div>
						</NavLink>
					</div>

					<div className='footer-contacts'>
						<div className='contacts-item'>
							<img src={icon1} alt='' />
							<div>+370 692 10831</div>
						</div>
						<div className='contacts-item'>
							<img src={icon2} alt='' />
							<div>vivatpay@gmail.com</div>
						</div>
						<div className='contacts-item'>
							<img src={icon3} alt='' />
							<div>Mo. - Fr.: 8 am – 5 pm</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import item from '../../../images-2/section1.svg'
import './section1.css'

export const Section1 = () => {
	const { t } = useTranslation()
	return (
		<>
			<section className='section1'>
				<div className='section1-block'>
					<div className='section1-title'>{t('section1-title')}</div>
					<div className='section1-text'>{t('section1-text')}</div>
					<NavLink to='/registration'>
						<div className='section1-btn'>
							<button className='btn-blue'>{t('section1-btn')}</button>
						</div>
					</NavLink>
				</div>
				<img src={item} alt='' />
			</section>
		</>
	)
}
